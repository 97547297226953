import React from 'react'
import Inview from 'components/Inview'

export default function BannerTitleOnlyBlock({
  content,
  padTop,
  padTopMore,
  padBottom,
  lineBottom,
  marginBottom,
}) {
  const { pretitle, title } = content
  const padTopClass = padTop ? 'pad-top' : ''
  const padTopMoreClass = padTopMore ? 'pad-top-more' : ''
  const padBottomClass = padBottom ? 'pad-bottom' : ''
  const marginBottomClass = marginBottom ? 'margin-bottom' : ''
  return (
    <section className='banner-title-only-block'>
      <Inview className={`container ${marginBottomClass} fade-in up`}>
        <div
          className={`text ${padTopClass} ${padTopMoreClass} ${padBottomClass}`}
        >
          {pretitle && <h3>{pretitle}</h3>}
          {title && <h1>{title}</h1>}
        </div>
        {lineBottom && <div className='line-bottom' />}
      </Inview>
    </section>
  )
}
