import React from 'react'
import LinkWrap from 'components/LinkWrap'
import { useLocalization } from 'gatsby-theme-i18n'

const LineLink = ({ content }) => {
  let { to, email, text } = content
  const { locale } = useLocalization()
  const fallback = {
    en: 'Learn More',
    zh: '了解更多',
  }
  if (!text) text = fallback[locale]

  if (to) {
    return (
      <LinkWrap to={to} className='component-line-link'>
        <span>{text}</span>
      </LinkWrap>
    )
  } else if (email) {
    return (
      <a href={`mailto:${email}`} className='component-line-link'>
        <span>{text}</span>
      </a>
    )
  } else {
    return (
      <div className='component-line-link'>
        <span>{text}</span>
      </div>
    )
  }
}

export default LineLink
