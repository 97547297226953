import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import BannerTitleOnlyBlock from 'components/blocks/BannerTitleOnlyBlock'
import ProductsBlock from 'components/blocks/ProductsBlock'
import NewsList from 'components/NewsList'
import PostGridBlock from 'components/blocks/PostGridBlock'
import SearchFilter from 'components/SearchFilter'
import ImageWrapLocal from 'components/ImageWrapLocal'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function SearchPage({ data }) {
  const { locale } = useLocalization()
  const page = data.strapiSearchPage
  const products = data.allStrapiProduct.nodes
  const productCollections = data.allStrapiCollection.nodes
  const productCategories = data.allStrapiProductCategory.nodes
  const productMaterials = data.allStrapiProductMaterial.nodes
  const newsPosts = data.allStrapiNewsPost.nodes
  const newsPostCategories = data.allStrapiNewsPostCategory.nodes
  const awardsPosts = data.allStrapiAwardsPost.nodes

  const filters = {
    section: 'products',
    newsCategories: [],
    productCollections: [],
    productCategories: [],
    productMaterials: [],
    keyword: '',
  }
  const [activeFilterState, setActiveFilterState] = useState(filters)
  const [searchFilterOpen, setSearchFilterOpen] = useState(false)

  const toggleSearchFilterOpen = () => {
    document.querySelector('.header.top').classList.toggle('search-in')
    setSearchFilterOpen(!searchFilterOpen)
  }

  const updateFilterRadio = (e) => {
    const filter = e.target.dataset.filter
    const value = e.target.dataset.value
    setActiveFilterState((prevState) => ({
      ...prevState,
      [filter]: 'x',
    }))
    setTimeout(() => {
      setActiveFilterState((prevState) => ({
        ...prevState,
        [filter]: value,
      }))
    }, 1)
  }

  const updateFilterCheckbox = (e) => {
    const filter = e.target.dataset.filter
    const value = e.target.dataset.value
    setActiveFilterState((prevState) => {
      let newValues = [...prevState[filter]]
      if (newValues.includes(value)) {
        newValues = newValues.filter((newValue) => newValue !== value)
      } else {
        newValues.push(value)
      }
      return { ...prevState, [filter]: newValues }
    })
  }

  const updateSearchKeyword = (e) => {
    const keyword = e.target.value.toLowerCase()
    setActiveFilterState((prevState) => ({
      ...prevState,
      keyword: 'xxxxxx',
    }))
    setTimeout(() => {
      setActiveFilterState((prevState) => ({
        ...prevState,
        keyword,
      }))
    }, 1)
  }

  useEffect(() => {
    setTimeout(() => toggleSearchFilterOpen(), 1001)
  }, [])

  return (
    <div className='page-search'>
      <BannerTitleOnlyBlock content={{ pretitle: page.title }} padTop={true} />
      <div className='filter-bar container'>
        <div className='inner'>
          <a className='filter image-wrap' onClick={toggleSearchFilterOpen}>
            <ImageWrapLocal filename='filter.png' />
            {tr('filter', locale)}
          </a>
        </div>
      </div>
      <Results
        activeFilterState={activeFilterState}
        products={products}
        newsPosts={newsPosts}
        awardsPosts={awardsPosts}
      />
      <SearchFilter
        searchFilterOpen={searchFilterOpen}
        toggleSearchFilterOpen={toggleSearchFilterOpen}
        activeFilterState={activeFilterState}
        updateFilterRadio={updateFilterRadio}
        updateFilterCheckbox={updateFilterCheckbox}
        updateSearchKeyword={updateSearchKeyword}
        productCollections={productCollections}
        productCategories={productCategories}
        productMaterials={productMaterials}
        newsPostCategories={newsPostCategories}
      />
    </div>
  )
}

const Results = ({ activeFilterState, products, newsPosts, awardsPosts }) => {
  return (
    <div className='results' style={{ minHeight: '100vh' }}>
      {activeFilterState.section === 'products' && (
        <ProductsBlock
          products={products}
          activeCollectionSlugs={activeFilterState.productCollections}
          activeCatSlugs={activeFilterState.productCategories}
          activeMatSlugs={activeFilterState.productMaterials}
          activeKeyword={activeFilterState.keyword}
          isFull={true}
        />
      )}
      {activeFilterState.section === 'news' && (
        <NewsList
          posts={newsPosts}
          activeCatSlugs={activeFilterState.newsCategories}
          activeKeyword={activeFilterState.keyword}
        />
      )}
      {activeFilterState.section === 'awards' && (
        <PostGridBlock
          posts={awardsPosts}
          activeKeyword={activeFilterState.keyword}
        />
      )}
    </div>
  )
}

export const pageQuery = graphql`
  query ($locale: String!) {
    strapiSearchPage(locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
    }
    allStrapiProduct(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        excerpt
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        productCollection {
          slug
        }
        productCategory {
          slug
        }
        productMaterial {
          slug
        }
      }
    }
    allStrapiCollection(
      filter: { locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      nodes {
        title
        slug
      }
    }
    allStrapiProductCategory(
      filter: { locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      nodes {
        title
        slug
      }
    }
    allStrapiProductMaterial(
      filter: { locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      nodes {
        title
        slug
      }
    }
    allStrapiNewsPost(
      filter: { locale: { eq: $locale } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        slug
        date
        bannerImageOnlyBlock {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        newsPostCategory {
          title
          slug
        }
      }
    }
    allStrapiNewsPostCategory(
      filter: { locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      nodes {
        title
        slug
      }
    }
    allStrapiAwardsPost(
      filter: { locale: { eq: $locale } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        excerpt
        slug
        date
        bannerImageOnlyBlock {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          textColor
        }
      }
    }
  }
`
