import React, { useState, useEffect } from 'react'
import Inview from 'components/Inview'
import ImageWrapLocal from 'components/ImageWrapLocal'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function SearchFilter({
  searchFilterOpen,
  toggleSearchFilterOpen,
  activeFilterState,
  updateFilterRadio,
  updateFilterCheckbox,
  updateSearchKeyword,
  productCollections,
  productCategories,
  productMaterials,
  newsPostCategories,
}) {
  const { locale } = useLocalization()
  let showClass = ''
  if (searchFilterOpen) showClass = 'show'
  return (
    <>
      <div
        className={`side-in-overlay ${showClass}`}
        onClick={toggleSearchFilterOpen}
      />
      <div
        className={`side-in search-filter ${searchFilterOpen ? 'open' : ''}
    `}
      >
        <Inview className='inner fade-in'>
          <ul className='menu'>
            {/* SECTION */}
            <li
              className={`fade-in up open-m0 has-submenu show 
            ${searchFilterOpen ? 'mobile-menu-open' : ''}`}
            >
              <a className='empty '>
                <div className='inner'>{tr('section', locale)}</div>
              </a>
              <ul className='submenu'>
                <li className='open-m0'>
                  <p>
                    <a
                      onClick={updateFilterRadio}
                      data-filter='section'
                      data-value='products'
                      className={
                        activeFilterState.section === 'products' ? 'active' : ''
                      }
                    >
                      <span className='select radio' />
                      {tr('jewelry', locale)}
                    </a>
                  </p>
                </li>

                {/* <li className='open-m0'>
                  <p>
                    <a
                      onClick={updateFilterRadio}
                      data-filter='section'
                      data-value='news'
                      className={
                        activeFilterState.section === 'news' ? 'active' : ''
                      }
                    >
                      <span className='select radio' />
                      {tr('news', locale)}
                    </a>
                  </p>
                </li> */}
                {/* <li className='open-m0'>
                  <p>
                    <a
                      onClick={updateFilterRadio}
                      data-filter='section'
                      data-value='awards'
                      className={
                        activeFilterState.section === 'awards' ? 'active' : ''
                      }
                    >
                      <span className='select radio' />
                      {tr('awards', locale)}
                    </a>
                  </p>
                </li> */}
              </ul>
            </li>

            {/* SEARCH */}
            <li
              className={`fade-in up open-m0 has-submenu show search-parent
            ${searchFilterOpen ? 'mobile-menu-open' : ''}
            `}
            >
              <a className='empty '>
                <div className='inner'>{tr('keyword', locale)}</div>
              </a>
              <ul className='submenu'>
                <li className='open-m0'>
                  <div className='search-wrap'>
                    <input type='text' onKeyUp={updateSearchKeyword} />
                    <div className='image'>
                      <ImageWrapLocal filename='search.png' />
                    </div>
                  </div>
                </li>
              </ul>
            </li>

            <FilterCheckboxGroup
              title={tr('collection', locale)}
              items={productCollections}
              filterType='productCollections'
              parent='products'
              searchFilterOpen={searchFilterOpen}
              updateFilterCheckbox={updateFilterCheckbox}
              activeFilterState={activeFilterState}
            />

            <FilterCheckboxGroup
              title={tr('productCategory', locale)}
              items={productCategories}
              filterType='productCategories'
              parent='products'
              searchFilterOpen={searchFilterOpen}
              updateFilterCheckbox={updateFilterCheckbox}
              activeFilterState={activeFilterState}
            />

            <FilterCheckboxGroup
              title={tr('productMaterial', locale)}
              items={productMaterials}
              filterType='productMaterials'
              parent='products'
              searchFilterOpen={searchFilterOpen}
              updateFilterCheckbox={updateFilterCheckbox}
              activeFilterState={activeFilterState}
            />

            <FilterCheckboxGroup
              title={tr('newsCategory', locale)}
              items={newsPostCategories}
              filterType='newsCategories'
              parent='news'
              searchFilterOpen={searchFilterOpen}
              updateFilterCheckbox={updateFilterCheckbox}
              activeFilterState={activeFilterState}
            />

            <li
              className={`fade-in up open-m0 has-submenu show
            ${searchFilterOpen ? 'mobile-menu-open' : ''}`}
            >
              <button className='filter' onClick={toggleSearchFilterOpen}>
                <ImageWrapLocal filename='filter.png' />
                {tr('closeFilter', locale)}
              </button>
            </li>
          </ul>
        </Inview>
      </div>
    </>
  )
}

const FilterCheckboxGroup = ({
  title,
  items,
  filterType,
  parent,
  searchFilterOpen,
  updateFilterCheckbox,
  activeFilterState,
}) => {
  const [openM0, setOpenM0] = useState(false)
  const toggleOpenM0 = () => setOpenM0(!openM0)
  return (
    <li
      className={`fade-in up has-submenu 
        ${openM0 ? 'open-m0' : ''}
        ${searchFilterOpen ? 'mobile-menu-open' : ''}
        ${activeFilterState.section === parent ? 'show' : ''}
      `}
    >
      <a onClick={toggleOpenM0}>
        <div className='inner'>
          {title}
          <span className='trigger'></span>
        </div>
      </a>

      <ul className='submenu'>
        {items.map((category, i) => (
          <FilterCheckbox
            key={i}
            i={i}
            content={category}
            filterType={filterType}
            updateFilterCheckbox={updateFilterCheckbox}
            activeFilterState={activeFilterState}
            openM0={openM0}
          />
        ))}
      </ul>
    </li>
  )
}

const FilterCheckbox = ({
  i,
  content,
  filterType,
  activeFilterState,
  updateFilterCheckbox,
  openM0,
}) => {
  const { title, slug } = content
  return (
    <li
      className={`fade-in up stagger-${i * 50 + 0}
        ${openM0 ? 'open-m0' : ''}
    `}
    >
      <p>
        <a
          onClick={updateFilterCheckbox}
          data-filter={filterType}
          data-value={slug}
          className={
            activeFilterState[filterType].includes(slug) ? 'active' : ''
          }
        >
          <span className='select checkbox' />
          {title}
        </a>
      </p>
    </li>
  )
}
