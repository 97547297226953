import React from 'react'
import OverlayItem from 'components/OverlayItem'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function ProductsBlock({
  products,
  activeCollectionSlugs,
  activeCatSlugs,
  activeMatSlugs,
  activeKeyword,
  isRelated,
  isFull,
}) {
  const { locale } = useLocalization()
  const bgBeigeClass = isRelated ? 'bg-beige' : ''
  const isFullClass = isFull ? 'is-full' : ''
  const padClass = isRelated ? 'pad-v-standard no-collapse' : 'pad-v-standard'
  return (
    <section className={`products-block ${padClass} ${bgBeigeClass}`}>
      <div className='container'>
        {isRelated && <h3>{tr('relatedProducts', locale)}</h3>}
        <div className={`grid  ${isFullClass}`}>
          {products.map((product, i) => {
            if (
              activeCollectionSlugs &&
              activeCollectionSlugs.length > 0 &&
              !activeCollectionSlugs.includes(product.productCollection?.slug)
            ) {
              return false
            }
            if (
              activeCatSlugs &&
              activeCatSlugs.length > 0 &&
              !activeCatSlugs.includes(product.productCategory?.slug)
            ) {
              return false
            }
            if (
              activeMatSlugs &&
              activeMatSlugs.length > 0 &&
              !activeMatSlugs.includes(product.productMaterial?.slug)
            ) {
              return false
            }
            if (
              activeKeyword &&
              !product.title.toLowerCase().includes(activeKeyword)
            ) {
              return false
            }
            return (
              <OverlayItem
                key={i}
                content={product}
                version='product'
                forceTitle={true}
                showLineLink={true}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}
