import React from 'react'
import Inview from 'components/Inview'
import ImageWrap from 'components/ImageWrap'
import ButtonLink from 'components/ButtonLink'

export default function PostGridBlock({ posts, activeKeyword }) {
  return (
    <section className='post-grid-block pad-v-standard'>
      <div className='container'>
        <div className='grid'>
          {posts.map((post, i) => {
            if (
              activeKeyword &&
              !post.title.toLowerCase().includes(activeKeyword)
            ) {
              return false
            }
            return <Post key={i} content={post} />
          })}
        </div>
      </div>
    </section>
  )
}

const Post = ({ content }) => {
  const { title, excerpt, date, slug, bannerImageOnlyBlock } = content
  return (
    <Inview className='post fade-in up'>
      {bannerImageOnlyBlock?.image && (
        <div className='image'>
          <ImageWrap image={bannerImageOnlyBlock.image} />
        </div>
      )}
      <div className='text'>
        <div className='inner'>
          <h3>{date}</h3>
          <h2>{title}</h2>
          <p>{excerpt}</p>
          <ButtonLink to={`/awards/${slug}`} />
        </div>
      </div>
    </Inview>
  )
}
