import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import LinkWrap from 'components/LinkWrap'
import LineLink from 'components/LineLink'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function Product({
  content,
  version,
  forceTitle,
  showLineLink,
}) {
  const { locale } = useLocalization()
  const { title, excerpt, image, slug } = content

  let link, linkText
  if (version === 'product') {
    link = `/products/${slug}/`
    linkText = tr('viewProduct', locale)
  }
  if (version === 'diamond') {
    link = `/diamonds/${slug}/`
  }
  if (version === 'collection') {
    link = `/collections/${slug}/`
    linkText = tr('viewCollection', locale)
  }
  const forceTitleClass = forceTitle ? 'force-title' : ''
  return (
    <Inview className='component-overlay-item fade-in up'>
      <LinkWrap to={link} className='block'>
        <div className='inner'>
          <div className='image'>
            <ImageWrap image={image} />
          </div>
          <div className='overlay'>
            <div className='bg' />
            <div className='text text-color-inverse'>
              <div className='wrap'>
                <h2 className={`smaller ${forceTitleClass}`}>{title}</h2>
                {excerpt && <p className='smaller'>{excerpt}</p>}
                {showLineLink && (
                  <h4>
                    <LineLink content={{ text: linkText }} />
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </LinkWrap>
      <div className={`title ${forceTitleClass}`}>
        <h2 className='smaller'>{title}</h2>
      </div>
    </Inview>
  )
}
