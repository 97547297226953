import React from 'react'
import Inview from 'components/Inview'
import ImageWrap from 'components/ImageWrap'
import ButtonLink from 'components/ButtonLink'
import { useLocalization } from 'gatsby-theme-i18n'

export default function NewsList({
  posts,
  activeCatSlug,
  activeCatSlugs,
  activeKeyword,
}) {
  return (
    <div className='news-list container'>
      {posts.map((post, i) => {
        if (activeCatSlug && activeCatSlug !== post.newsPostCategory?.slug) {
          return false
        }
        if (
          activeCatSlugs &&
          activeCatSlugs.length > 0 &&
          !activeCatSlugs.includes(post.newsPostCategory?.slug)
        ) {
          return false
        }
        if (
          activeKeyword &&
          !post.title.toLowerCase().includes(activeKeyword)
        ) {
          return false
        }
        return <Post key={i} content={post} activeCatSlug={activeCatSlug} />
      })}
    </div>
  )
}

const Post = ({ content }) => {
  const { locale } = useLocalization()
  const { title, date, slug, bannerImageOnlyBlock, newsPostCategory } = content
  let setPretitle = date
  if (newsPostCategory?.title) {
    setPretitle += ` &nbsp;|&nbsp; ${newsPostCategory.title}`
  }

  return (
    <Inview className='post fade-in up'>
      {bannerImageOnlyBlock?.image && (
        <div className='image'>
          <ImageWrap image={bannerImageOnlyBlock.image} />
        </div>
      )}
      <div className='text'>
        <div className='inner'>
          <h3 dangerouslySetInnerHTML={{ __html: setPretitle }} />
          <h2>{title}</h2>
          <ButtonLink to={`/news/${slug}`} />
        </div>
      </div>
    </Inview>
  )
}
